import { useCallback, useEffect, useMemo, useState } from "react";
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro";
import { PAGE_URL } from "../../../constants";
import { ContactDenorm, ContactOrgDenorm } from "../../../store";
import { crm } from "../../../store/cabinetApi/crm";
import { selectDenormalizedData } from "../../../store/crm/selectors";
import { transformMuiDataGridFilterSortToApiModel } from "../../../utils/dataUtils";
import { AppCrmCompaniesListApiArg, Company } from "../../../store/cabinetApi/generated/crm";
import { useDeepCompareEffect } from "react-use";
import { useCabNavigate } from "../../../utils/hooks";


type OrgsApiFilter = Omit<AppCrmCompaniesListApiArg, 'offset'|'limit'>;

const useCompaniesList = ({ pageSize, defaultFilter = {items: []}, defaultSort, search }: {
  pageSize: number; defaultFilter?: GridFilterModel; defaultSort?: GridSortModel; search?: string
}) => {
  const [orgPage, setOrgPage] = useState(0);
  const [orgsFilter, setOrgsFilter] = useState<GridFilterModel>(defaultFilter || { items: [] });
  const [orgsSort, setOrgsSort] = useState<GridSortModel>(
    defaultSort || [{ field: 'name', sort: 'asc' }]
  );

  const orgApiFilter: OrgsApiFilter = useMemo(() => {
    return transformMuiDataGridFilterSortToApiModel({ filterModel: orgsFilter, sortModel: orgsSort }, true);
  }, [orgsFilter, orgsSort]);

  const { data: orgData, isFetching: loadingCompanies } = crm.useAppCrmCompaniesListQuery({
    limit: pageSize, offset: orgPage * pageSize, search, hiddenDateIsnull: true,
    ...orgApiFilter,
  });

  const [fetchContacts] = crm.useLazyAppCrmContactsListQuery();
  // TODO: remove limit from this request. Only adding it to get the right response shape for now
  const { data: orgCategories } = crm.useAppCrmCompanyCategoriesListQuery({ limit: 1000 });

  const orgs = orgData && selectDenormalizedData(orgData) as ContactOrgDenorm[];

  const [updateCompany] = crm.useAppCrmCompaniesPartialUpdateMutation();

  const handleFetchCompanyContacts = useCallback(async (companyId: ContactOrgDenorm['id']) => {
    const { data: contactData } = await fetchContacts({ limit: 1000, company: companyId }, true);
    return (contactData && selectDenormalizedData(contactData) as ContactDenorm[]) || [];
  }, [fetchContacts]);

  const navigate = useCabNavigate();

  const handleNavigateToOrganization = (orgId: Company['id']) => (
    navigate(`${PAGE_URL.CRM_COMPANIES}/${orgId}`)
  );

  const handleSetOrgsFilter = (filter: GridFilterModel | undefined) => {
    setOrgPage(0);
    setOrgsFilter(filter || { items: [] });
  };

  const handleSetOrgsSort = (sort: GridSortModel | undefined) => {
    setOrgPage(0);
    setOrgsSort(sort || []);
  };

  const handleUpdateCompany = useCallback(async (
    company: Partial<Omit<ContactOrgDenorm, 'primary_contact'>> & { id: ContactOrgDenorm['id'] }
  ) => {
    const res = await updateCompany({ id: company.id, patchedCompany: {
      category: company.category,
      hidden_date: company.hidden_date,
    } });
    if ('data' in res) {
      return selectDenormalizedData(res.data) as ContactOrgDenorm;
    }
  }, [updateCompany]);

  useDeepCompareEffect(() => setOrgsFilter(defaultFilter || { items: [] }), [defaultFilter]);
  // reset filter anytime search changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setOrgsFilter(defaultFilter || { items: [] }), [search]);

  return {
    organizations: orgs || [],
    rowCount: orgData?.meta?.count || 0,
    loadingCompanies: loadingCompanies,
    filter: orgsFilter,
    sort: orgsSort,
    categories: orgCategories?.data || [],
    page: orgPage,
    pageSize,
    onNavigateToOrganization: handleNavigateToOrganization,
    onFetchCompanyContacts: handleFetchCompanyContacts,
    onSetFilter: handleSetOrgsFilter,
    onSetSort: handleSetOrgsSort,
    onPageChange: setOrgPage,
    onUpdateCompany: handleUpdateCompany,
  };
};

export default useCompaniesList;
