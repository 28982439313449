import { ReactNode, useCallback, useMemo } from "react";
import {
  GridColDef, GridEventListener, GridFilterModel, GridSortModel, GridValueOptionsParams, getGridSingleSelectOperators
} from "@mui/x-data-grid-pro";
import { ContactDenorm, ContactOrgDenorm } from "../../../store";
import { CompanyCategory, Contact } from "../../../store/cabinetApi/generated/crm";
import CRMTable from '../CRMTable';
import { Box, SxProps } from "@mui/material";
import { CabToggleChip } from "@CabComponents/CabToggleChip";
import colors from "../../../colors";
import { IoEyeOff } from "react-icons/io5";
import { DateTime } from "luxon";


const organizationColumns: GridColDef<ContactOrgDenorm>[] = [
  {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    display: "flex",
    flex: 2,
    minWidth: 250,
  },
  {
    field: 'email_domain',
    headerName: 'Email Domain',
    type: 'string',
    sortable: false,
    display: "flex",
    width: 200,
  },
  {
    field: 'notes',
    headerName: 'Notes',
    type: 'string',
    sortable: false,
    display: "flex",
    width: 200,
  },
  {
    field: 'primary_contact',
    headerName: 'Primary Contact',
    type: 'singleSelect',
    sortable: false,
    display: "flex",
    width: 200,
    // Seems declaring the type here is intended? mui-x maintainer did so in his example here:
    // https://github.com/mui/mui-x/issues/12914#issuecomment-2079571420
    valueGetter: (value: Contact | null | undefined, row, column, apiRef) => value?.id,
    filterOperators: getGridSingleSelectOperators().filter(op => op.value !== 'not'),
  },
  {
    field: 'category',
    headerName: 'Category',
    type: 'singleSelect',
    editable: true,
    width: 200,
    display: "flex",
    filterOperators: getGridSingleSelectOperators().filter(op => op.value !== 'not'),
    valueGetter: (value, row, column, apiRef) => value ?? -1,
    renderCell: (params) => (
      <CabToggleChip
        // @ts-expect-error type missing valueOptions
        chipColor={params.colDef.valueOptions.find(o => o.value === params.value)?.color}
        sx={{ width: '150px' }}
        label={params.formattedValue || 'Unassigned'}
        selected={true}
      />
    ),

  },
];


const OrganizationTable = ({
  organizations, categories, onNavigateToOrganization, page, pageSize, rowCount, onPageChange, filter, onSetFilter,
  onFetchCompanyContacts, sort, onSetSort, loadingCompanies, interactive, paginate, header, footer, onUpdateCompany, sx
}: {
  organizations: ContactOrgDenorm[];
  onFetchCompanyContacts: (companyId: ContactOrgDenorm['id']) => Promise<ContactDenorm[]>;
  header?: ReactNode;
  footer?: ReactNode;
  categories: CompanyCategory[];
  page: number;
  pageSize: number;
  rowCount: number;
  onPageChange: (page: number) => void;
  onNavigateToOrganization: (id: ContactOrgDenorm['id']) => void
  filter?: GridFilterModel;
  onSetFilter: (filter: GridFilterModel | undefined) => void;
  sort?: GridSortModel;
  onSetSort: (filter: GridSortModel | undefined) => void;
  loadingCompanies: boolean;
  interactive: boolean;
  paginate: boolean;
  onUpdateCompany: (
    company: Partial<Omit<ContactOrgDenorm, 'primary_contact'>> & { id: ContactOrgDenorm['id'] }
  ) => Promise<ContactOrgDenorm | undefined>
  sx?: SxProps;
}) => {
  const handleCellClick = useCallback<GridEventListener<'cellClick'>>((params, event, details) => {
    if (params.field === 'name') {
      onNavigateToOrganization(params.row.id);
    }
  }, [onNavigateToOrganization]);

  const columns = useMemo((): GridColDef<ContactOrgDenorm>[] => organizationColumns.map(c => {
    if (c.field === 'category') {
      return {
        ...c,
        valueOptions: categories.map(cat => ({ value: cat.id, label: cat.name, color: cat.color }))
          .concat({ value: -1, label: 'Unassigned', color: colors.black200 }),
      };
    } else if (c.field === 'primary_contact') {
      return {
        ...c,
        valueOptions: ({ row }: GridValueOptionsParams<ContactOrgDenorm>) => {
          if (!row?.primary_contact) return [];
          return [{ value: row.primary_contact.id, label: row.primary_contact.name || '' }];
        },
        // NOTE: As of 6/25/24 async valueOptions function is not supported: https://github.com/mui/mui-x/issues/13240
        // Would be nice to have this in the future so we can get all options for a company
        // valueOptions: async ({ row }: GridValueOptionsParams<ContactOrgDenorm>) => {
        //   if (!row) return [];
        //   const contacts = await onFetchCompanyContacts(row.id);
        //   // console.log(contacts);
        //   console.log(row.primary_contact);
        //   const options = uniqBy(
        //     (row.primary_contact
        //       ? [{ value: row.primary_contact.id, label: row.primary_contact.name || '' }]
        //       : []).concat(contacts.map(o => ({ value: o.id, label: o.name || '' }))),
        //     'value',
        //   );
        //   console.log(options)
        //   return options;
        // },
      };
    }
    return c;
  }), [categories]);

  const handleCompanyRowUpdate = useCallback(async (updatedRow: ContactOrgDenorm, originalRow: ContactOrgDenorm) => {
    const update: ContactOrgDenorm = {
      ...updatedRow,
      category: updatedRow.category === -1 ? null : updatedRow.category,
    };
    const res = await onUpdateCompany(update);
    return res || originalRow;
  }, [onUpdateCompany]);

  const handleHideCompany = useCallback(async (companyId: ContactOrgDenorm['id']) => {
    await onUpdateCompany({ id: companyId, hidden_date: DateTime.now().toISO() });
  }, [onUpdateCompany]);

  return (
    <>
      {header}
      <Box sx={sx}>
        <CRMTable
          columns={columns}
          rows={organizations}
          page={page}
          pageSize={pageSize}
          rowCount={rowCount}
          onPageChange={onPageChange}
          onCellClick={handleCellClick}
          filter={filter}
          onSetFilter={onSetFilter}
          sort={sort}
          onSetSort={onSetSort}
          loading={loadingCompanies}
          interactive={interactive}
          paginate={paginate}
          onRowUpdate={handleCompanyRowUpdate}
          actions={[{ name: 'Hide', key: 'hide', onClick: (row) => handleHideCompany(row.id), icon: IoEyeOff }]}
        />
      </Box>
      {footer}
    </>
  );
};

export default OrganizationTable;
